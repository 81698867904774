<template>
  <div class="main">
    <div class="container">
      <el-dialog
        :visible.sync="invitationDialog"
        width="417px"
        :fullscreen="true"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <div class="content">
          <v-ueditor :maxLength="20000" v-model="content" />
          <v-input
            v-model="title"
            width="100%"
            style="margin: 20px 0"
            placeholder="HTML Title"
          ></v-input>
          <v-input
            v-model="file"
            width="100%"
            style="margin-bottom: 20px"
            placeholder="文件名"
          ></v-input>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="save">保存为HTML</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// 引入组件
export default {
  name: "HelloM",
  components: {},
  data() {
    return {
      title: "",
      file: "",
      content: "",
      config: {
        UEDITOR_HOME_URL: "/ueditor/", // 需要令此处的URL等于对应 ueditor.config.js 中的配置。
      },
      invitationDialog: true,
    };
  },
  methods: {
    save() {
      if (!this.file) {
        alert("请输入文件名");
        return;
      }
      var html = [];
      html.push(
        '<!DOCTYPE html><html lang="zh"><head><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="ie=edge"><meta name="keywords" content="" /><meta name="description" content="" /><meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=0" /><meta name="format-detection" content="telephone=no" /><meta name="apple-mobile-web-app-capable" content="yes" /><meta name="apple-mobile-web-app-status-bar-style" content="black"><title>'
      );
      html.push(this.title);
      html.push("</title>");
      html.push(
        '</head><style>*{margin:0;padding:0;}body {line-height: 1.5;}.content{padding:15px;}p {margin: 0;padding: 0;text-indent: 0 !important;}p span {font-size: 15px !important;font-family: "Helvetica Neue", Helvetica, STHeiTi, sans-serif !important;}table {border: 1px solid #333;border-spacing: 0;width: 100%!important;table-layout:fixed;}table tr td{width: 0 !important;border:1px solid #333;}</style><body>'
      );
      html.push('<div class="content">');
      html.push(this.content);
      html.push("</div></body></html>");
      this.downloadHtml(html.join(""), this.file);
    },
    downloadHtml(content, filename) {
      // 创建隐藏的可下载链接
      var eleLink = document.createElement("a");
      eleLink.download = filename + ".html";
      eleLink.style.display = "none";
      // 字符内容转变成blob地址
      var blob = new Blob([content]);
      eleLink.href = URL.createObjectURL(blob);
      document.body.appendChild(eleLink);
      // 点击
      eleLink.click();
      document.body.removeChild(eleLink);
    },
  },
};
</script>
<style lang="less">
#edui1 {
  width: 400px;
}
</style>
